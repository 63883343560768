<template>
  <div>
    <a-row type="flex" style="margin:5px 15px;">

      <!-- Header Breadcrumbs & Title Column -->
      <a-col :span="24" :md="24">

      <div class="ant-page-header-heading" v-if="selectedItem">
      <span class="ant-page-header-heading-title text-primary">{{ selectedItem.title }} <span style="color:rgba(0, 0, 0, 0.45);font-weight: 600;">#{{selectedItem.eventNumber}}</span>

      <a-tag  class="ant-tag-primary" :class="{'ant-tag-success':selectedItem.status=='published'||selectedItem.status=='Published','ant-tag-danger':selectedItem.status=='deleted'||selectedItem.status=='Deleted','ant-tag-warning':selectedItem.status=='Inactive'||selectedItem.status=='inactive'}" style="margin-left: 10px">
          {{ selectedItem?selectedItem.status:'' | capitalizeFirstLetter }}
        </a-tag></span>
    </div>
        <div v-else>
          <a-skeleton active title />
        </div>
      </a-col>
    </a-row>
    <a-tabs v-model="activeKey"  @change="callback">
      <a-tab-pane key="1"  tab="Basic Information">
        <router-view v-if="activeKey == 1" @changeTab="changeTab"></router-view>
      </a-tab-pane>
      <a-tab-pane key="6">
      <span slot="tab">
        Schedules & Tickets
        <a-tooltip placement="top">
                    <template slot="title">
                      <span>No schedules added</span>
                    </template>
<a-icon type="warning" class="blink-icon" v-if="selectedItem&&!selectedItem.schedules.length" theme="twoTone" two-tone-color="orange"/>
                  </a-tooltip>
      </span>
        <router-view v-if="activeKey == 6" @changeTab="changeTab" ></router-view>
      </a-tab-pane>
      <a-tab-pane key="2" >
        <span slot="tab">
        Description
        <a-tooltip placement="top">
                    <template slot="title">
                      <span>Description not added</span>
                    </template>
<a-icon type="warning"  class="blink-icon" v-if="selectedItem&&!selectedItem.description" theme="twoTone" two-tone-color="orange"/>
                  </a-tooltip>
      </span>
        <router-view v-if="activeKey == 2" @changeTab="changeTab" ></router-view>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Image Carousel" >
        <router-view v-if="activeKey == 3" @changeTab="changeTab" ></router-view>
      </a-tab-pane>
<!--      <a-tab-pane key="4" tab="Tickets" >-->
<!--        <router-view v-if="activeKey == 4" @changeTab="changeTab"></router-view>-->
<!--      </a-tab-pane>-->
      <a-tab-pane key="5" :tab="selectedItem.status=='draft'||selectedItem.status=='Draft'||selectedItem.status=='inactive'||selectedItem.status=='Inactive'?'Publish/Summary':'Summary'">
        <router-view v-if="activeKey == 5" @changeTab="changeTab"></router-view>
      </a-tab-pane>
<!--      <a-tab-pane key="7" tab="Stat">-->
<!--        <router-view v-if="activeKey == 7" ></router-view>-->
<!--      </a-tab-pane>-->
    </a-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeKey:this.$route.name=='Edit Event | Basic Info'?'1':(this.$route.name=='Edit Event | Images'?'3':(this.$route.name=='Edit Event | Descriptions'?'2':(this.$route.name=='Edit Event | Tickets'?'4':(this.$route.name=='Edit Event | Schedules'?'6':(this.$route.name=='Edit Event | Publish/Summary'?'5':'5'))))),
      prevKey:this.$route.name=='Edit Event | Basic Info'?'1':(this.$route.name=='Edit Event | Images'?'3':(this.$route.name=='Edit Event | Descriptions'?'2':(this.$route.name=='Edit Event | Tickets'?'4':(this.$route.name=='Edit Event | Schedules'?'6':(this.$route.name=='Edit Event | Publish/Summary'?'5':'5'))))),
    };
  },
  computed:{
    selectedItem(){
      return this.$store.getters.selectedEvent
    }
  },
  methods: {
    callback(key) {
      if (this.prevKey!='1'&&this.prevKey!='2'&&this.prevKey!='3') {
        this.prevKey = this.activeKey;
        if (this.activeKey=='1')
          this.$router.push('basic-info').catch((err)=>{})
        else if (this.activeKey=='3')
          this.$router.push('images').catch((err)=>{})
        else if (this.activeKey=='2')
          this.$router.push('descriptions').catch((err)=>{})
        // else if (this.activeKey=='4')
        //   this.$router.push({name: 'Edit Event | Tickets'})
        else if (this.activeKey=='5')
          this.$router.push('publish').catch((err)=>{})
        else if (this.activeKey=='6')
          this.$router.push('schedules').catch((err)=>{})
        // else if (this.activeKey=='7')
        //   this.$router.push('stats').catch((err)=>{})
      }
      else {
        if (confirm('Some changes might not have been saved. Are you sure want to proceed?')==true){
         if (this.activeKey=='1')
           this.$router.push('basic-info').catch((err)=>{})
         else if (this.activeKey=='3')
           this.$router.push('images').catch((err)=>{})
         else if (this.activeKey=='2')
           this.$router.push('descriptions').catch((err)=>{})
         // else if (this.activeKey=='4')
         //   this.$router.push({name: 'Edit Event | Tickets'})
         else if (this.activeKey=='5')
           this.$router.push('publish').catch((err)=>{})
         else if (this.activeKey=='6')
           this.$router.push('schedules').catch((err)=>{})
         // else if (this.activeKey=='7')
         //   this.$router.push('stats').catch((err)=>{})
         this.prevKey = this.activeKey;
       }
       else{
         this.activeKey = this.prevKey;
       }
       }


      },
    changeTab(key){
      this.prevKey = key;
      this.activeKey=key
      if (this.activeKey=='1')
        this.$router.push('basic-info').catch((err)=>{})
      else if (this.activeKey=='3')
        this.$router.push('images').catch((err)=>{})
      else if (this.activeKey=='2')
        this.$router.push('descriptions').catch((err)=>{})
      // else if (this.activeKey=='4')
      //   this.$router.push('tickets').catch((err)=>{})
      else if (this.activeKey=='5')
        this.$router.push('publish').catch((err)=>{})
      else if (this.activeKey=='6')
        this.$router.push('schedules').catch((err)=>{})
    },
    handleMenuClick(key){
      this.$confirm({
        title: 'Are you sure want to update the event status?',
        content: 'Are you sure want to continue?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          let status=this.selectedItem.status
          if (key.key=='11')
            status='deleted'
          else if (key.key=='22')
            status='inactive'
          else if (key.key=='33')
            status='draft'
          else if (key.key=='44')
            status='published'

          this.$store.dispatch("UPDATE_EVENT_STATUS", {status:status,id:this.selectedItem._id})
              .then((res) => {
                this.$message.success(res.data.message);
              })
              .catch((err) => {
                // this.$message.error(err.response.data.message);
              })
              .finally(() => {
                // this.confirmLoading = false
              });
        },
      });

    },
    eventInfo(){
      this.$store.dispatch("FETCH_EVENT",{id:this.$route.params.id})
          .then((res)=>{
          })
          .finally(()=>{
          })
    },

  },
  created() {
    if (this.$route.name=='Edit Event | Schedules')
      this.eventInfo()
  }
};
</script>